<template>
  <div>
    <SearchStateController :default="defaultFilters" :s="s" :params="searchParams" @change="onSearchChange">
      <template #default="{ state }">
        <SearchFiltersProfile :team_name="player_name" :value="state" @update="onSearch" />

        <hr />

        <AppTabs>
          <AppTab title="Found matches">
            <MatchSearchController
              :filter="state"
              :per-page.sync="perPage"
              :selected.sync="selectedMatches"
              :table="$options.components.MatchBookmarkController"
            />
          </AppTab>

          <AppTab :title="`Selected matches (${selectedMatchesCount})`">
            <MatchBookmarkController :items="selectedMatches" :selected.sync="selectedMatches" />
          </AppTab>

          <MapTab :matches="selectedMatches" />
        </AppTabs>
      </template>
    </SearchStateController>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import MatchBookmarkController from '@/components/controllers/bookmark/Match.vue'
import MatchSearchController from '@/components/controllers/search/Match.vue'
import SearchStateController from '@/components/controllers/SearchStateController.vue'
import AppTab from '@/components/generic/Tab.vue'
import AppTabs from '@/components/generic/Tabs.vue'
import SearchFiltersProfile from '@/components/Search/FiltersProfile.vue'
import MapTab from '@/components/tabs/MapTab.vue'
import mixpanel from '@/mixpanel'
import { DEFAULT_ITEMS_PER_PAGE } from '@/store/modules/search'
import { serializeQuery } from '@/utils/searchQuery'

export default {
  components: {
    MapTab,
    MatchBookmarkController,
    AppTab,
    AppTabs,
    SearchFiltersProfile,
    MatchSearchController,
    SearchStateController,
  },

  data: () => ({
    perPage: DEFAULT_ITEMS_PER_PAGE,
    selectedMatches: [],
  }),

  computed: {
    ...mapGetters({
      player_name: 'player/name',
      defaultFilters: 'search/playerMatchSearchFilters',
    }),

    s() {
      return this.$route.query.s
    },

    searchParams() {
      return {
        map_ids: [this.$route.params.map_id],
        player_ids: [this.$route.params.player_id],
      }
    },

    selectedMatchesCount() {
      return this.selectedMatches.length
    },
  },

  methods: {
    ...mapMutations({
      resetFilters: 'search/RESET_FILTERS',
      saveFilters: 'search/SET_FILTERS',
    }),

    async onSearch(query) {
      this.loading++
      try {
        this.selectedMatches = []
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({ query: { s: serializedQuery != null ? serializedQuery : undefined } })
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },

    onSearchChange() {
      this.selectedMatches = []
    },
  },
}
</script>

<style></style>
